
:root {
  --white: #ffffff;
  --black: #000000;
  --darkGray: #777e91;
  --offWhiteGray: #e2e2e2;
  --lightOffWhiteGray: #f8f8f8;
  --extraLightGray: #fcfcfc;
  --lightGray: #f5f5f5;
  --blackBase: #23262f;
  --purple: #801d77;
  --purpleLight: #ffffff9c;
  --darkBlue: #0066cc;
  --lightBlue: #67a9f5;
  --darkBaseBlack: #141416;
  --darkFillBlack: #23262f;
  --darkBorderBlack: #353945;
  --lightGreen: #84bc41;
  --darkGreen: #66873e;
}

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "config/variables";
@import "../../../node_modules/bootstrap/scss/mixins.scss";


// Structure
@import "custom/structure/general";
@import "custom/structure/left-menu";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/layouts";
@import "custom/structure/horizontal-nav";
@import "custom/structure/two-column-menu";

//Components
@import "custom/components/avatar";
@import "custom/components/custom-checkbox";
@import "custom/components/custom-radio";
@import "custom/components/helper";
@import "custom/components/social";
@import "custom/components/widgets";
@import "custom/components/print";
@import "custom/components/preloader";

//Pages
@import "custom/pages/authentication";
@import "custom/pages/components-demo";
@import "custom/pages/error";
@import "custom/pages/email";
@import "custom/pages/pricing";
@import "custom/pages/profile";
@import "custom/pages/task";
@import "custom/pages/timeline";
@import "custom/pages/kanbanboard";
@import "custom/pages/landing";
@import "custom/pages/file-manager.scss";
@import "custom/pages/chat";


// Plugins
@import "custom/plugins/apexcharts";
@import "custom/plugins/calendar";
@import "custom/plugins/colorpicker";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/datatable";
@import "custom/plugins/datepicker"; // react-datepicker style
@import "custom/plugins/form-wizard";
@import "custom/plugins/select2";
@import "custom/plugins/simplebar";
@import "custom/plugins/multiple-select";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/parsley";
@import "custom/plugins/quilljs-editor";
@import "custom/plugins/react-select"; // react-select style
@import 'custom/plugins/react-table'; // react table style
@import "custom/plugins/flatpickr";
@import "custom/plugins/dropzone";
@import "custom/plugins/google-maps";
@import "custom/plugins/vector-maps";

.import-question input {
  display: none;

}

.import-question .input-group-text {
  cursor: pointer;
  background-color: rgba(37, 194, 227, 0.15);
  color: #25c2e3;
  border-color: rgba(37, 194, 227, 0.15);
}

.coin_history_list {
  align-items: center;

  li {
    list-style: none;
    padding: 0px 8px;

  }

}


.cp {
  cursor: pointer;
}

.package-box-wrapper {

  // display: grid;
  // grid-template-columns: repeat(3, 1fr);
  // gap: 30px;
  .slick-slide>div {
    padding: 12px 15px;
  }

  .slick-dots {
    bottom: 15px;

    li {
      height: 11px;
      width: 11px;

      &.slick-active {
        background-color: var(--Blue);
        border: 1px solid var(--Blue);
        border-radius: 50%;
      }

      button {
        border-radius: 50%;
        height: 10px;
        width: 10px;

        &::before {
          content: '';
          border: 1px solid var(--blackLight);
          border-radius: 50%;
          height: 10px;
          width: 10px;
        }
      }
    }
  }

  .slick-arrow {
    &.slick-prev {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: var(--Blue);

      &::before {
        content: "\e90f";
        font-family: 'icomoon';
        opacity: 1;
        font-size: 14px;
      }
    }

    &.slick-next {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: var(--Blue);

      &::before {
        content: "\e911";
        font-family: 'icomoon';
        opacity: 1;
        font-size: 14px;
      }
    }
  }

  .card-box {
    margin-top: 10px;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    overflow: hidden;
    padding: 0px 0 15px;

    .image-section {
      img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 100%;
      }
    }

    .card-body {
      padding: 15px 15px;

      .title-section {
        h3 {
          font-size: 20px;
          font-weight: 600;
          padding: 0px 0px;
          margin: 0px 0px;
          color: var(--black);
        }
      }

      .price-section-wrapper {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .price-section {
          display: flex;
          gap: 10px;

          h4 {
            color: var(--Blue);
            font-weight: 800;
            font-size: 18px;
            margin: 0px;
            display: flex;
            align-items: center;
            gap: 5px;

            i {
              font-size: 14px;
              font-weight: 800;
            }
          }

          .select-price {
            .dropdown {
              .btn {
                background-color: var(--white);
                border-radius: 0px;
                border: 1px solid var(--GrayBorder);
                border: 0;
                color: var(--blackLight);
                font-size: 14px;
                padding: 0px;
                position: relative;

                &::after {
                  position: absolute;
                  top: 50%;
                  margin-left: 8px;
                  transform: translateX(-50%);
                }
              }

              .dropdown-menu {
                border-radius: 5px;
                border: 1px solid var(--GrayBorder);
                overflow: hidden;
                padding: 0px;

                .dropdown-item {
                  border-bottom: 1px solid var(--GrayExtraLight);
                  padding: 5px 8px;
                  display: flex;
                  align-items: center;
                  min-height: 40px;

                  .plan-select-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 15px;
                    // flex-direction: row-reverse;

                    .plan-price-discount {
                      background-color: var(--BlueLight);
                      border-radius: 5px;
                      padding: 5px 8px;
                      text-align: center;
                      min-width: 42px;

                      h5 {
                        font-size: 13px;
                        font-weight: 800;
                        color: var(--Blue);
                        margin: 0px;
                      }

                      p {
                        font-size: 12px;
                        margin: 0px;
                        color: var(--blackLight);
                        font-weight: 600;

                      }
                    }

                    .price {
                      h5 {
                        font-size: 15px;
                        font-weight: 800;
                        color: var(--blackLight);
                        margin: 0px;

                        i {
                          font-size: 12px;
                          font-weight: 800;
                        }

                        span {
                          font-size: 12px;
                          font-weight: 500;
                          color: var(--blackLight);
                          margin: 0px;

                          &.original-price {
                            i {
                              text-decoration: none;
                            }

                            margin-left: 5px;
                            text-decoration: line-through;
                            font-size: 15px;
                            font-weight: 800;
                            color: var(--light-red);
                          }
                        }
                      }
                    }
                  }
                }

                .dropdown-item {
                  &:active {
                    background-color: var(--GrayExtraLight);
                  }
                }
              }
            }
          }
        }

        .discount {
          color: var(--blackLight);
          font-weight: 800;
          font-size: 18px;
          margin: 0px;

          span {
            font-weight: 600;
            font-size: 15px;
          }
        }
      }

      .price-section-if-discount {
        i {
          font-size: 12px;
        }

        font-size: 13px;
        text-decoration: line-through;
        color: var(--light-red);
      }
    }
  }

  .plan-list {
    border-top: 1px solid var(--GrayBorder);
    padding: 15px 30px;
    max-height: 95px;
    overflow: hidden;

    ul {
      padding: 0px;
      margin: 0px;

      li {
        padding: 3px 0px;
        margin: 0px;
        font-size: 14px;
        color: var(--blackLight);
        list-style: disc;

      }
    }
  }

  .see-more-btn {
    margin: 0px 20px 0px 30px;

    span {
      font-size: 14px;
      color: var(--Blue);
      font-weight: 600;
      cursor: pointer;
    }
  }

  .btn_link {
    padding: 15px 15px;

    span {
      font-size: 15px;
      color: var(--white);
      background-color: var(--Blue);
      text-align: center;
      display: block;
      padding: 10px 20px;
      border-radius: 10px;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

.clone {
  position: relative;

  .crossButton {
    position: absolute;
    right: 0;
    top: 0;
    padding: 4px 7px;
  }
}

.uploaded-file {
  margin-top: 10px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid var(--lightGreen);
  background-color: var(--white);

  .text-primary {
    color: var(--darkGreen) !important;
  }

  .remove-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.width-30 {
  width: 30px;
}

.height-30 {
  height: 30px;
}

.image-checkbox-wrapper {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  .checkbox,
  .radio {
    width: 100px;
    height: 100px;
  }

  input[type="radio"],
  input[type="checkbox"] {
    display: none;
    position: absolute;
  }

  label {
    border: 2px solid #fff;
    padding: 5px;
    // display: block;
    position: relative;
    border-radius: 10px;
    cursor: pointer;
  }

  label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }

  label img {
    height: 80px;
    width: 80px;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }

  :checked+label {
    border-color: var(--lightGreen);
  }

  :checked+label:after {
    content: none;
    width: 0;
    height: 0;
  }

  :checked+label img {
    transform: scale(0.9);
    /* box-shadow: 0 0 5px #333; */
    z-index: -1;
  }
}

.invalid-feedback {
  text-transform: capitalize;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover {
  background: var(--offWhiteGray);
  cursor: not-allowed;
}

.treeview-wrapper {
  overflow-x: auto;
}

.treeview ul {
  padding-top: 20px;
  position: relative;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  display: flex;
}

.treeview li {
  // float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;
  flex-grow: 0 0 200px;
  min-width: 200px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*We will use ::before and ::after to draw the connectors*/

.treeview li::before,
.treeview li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 20px;
}

.treeview li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}

/*We need to remove left-right connectors from elements without 
any siblings*/
.treeview li:only-child::after,
.treeview li:only-child::before {
  display: none;
}

/*Remove space from the top of single children*/
.treeview li:only-child {
  padding-top: 0;
}

/*Remove left connector from first child and 
right connector from last child*/
.treeview li:first-child::before,
.treeview li:last-child::after {
  border: 0 none;
}

/*Adding back the vertical connector to the last nodes*/
.treeview li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}

.treeview li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

/*Time to add downward connectors from parents*/
.treeview ul ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 20px;
}

.treeview li .main-wrapper {
  border: 1px solid #ccc;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;

  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*Time for some hover effects*/
/*We will apply the hover effect the the lineage of the element also*/
.treeview li .main-wrapper:hover,
.treeview li .main-wrapper:hover+ul li a {
  color: var(--darkGreen);
  border: 1px solid #94a0b4;
}

/*Connector styles on hover*/
.treeview li .main-wrapper:hover+ul li::after,
.treeview li .main-wrapper:hover+ul li::before,
.treeview li .main-wrapper:hover+ul::before,
.treeview li .main-wrapper:hover+ul ul::before {
  border-color: #94a0b4;
}